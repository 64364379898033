import {
  Action,
  Contact,
  ErrorCodes,
  Menu,
  Order,
  OrderFailureType,
  Restaurant,
  VirtualDispatchType,
} from '@wix/restaurants-client-logic';
import { Dispatch } from 'redux';
import type { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { setCheckoutStep, setDeliveryProviderEstimate, setErrorType, submitOrderSuccess } from './checkout.actions';
import {
  clearSessionStorage,
  navigate,
  openModal,
  saveStateToSessionStorage,
  setIsLocationPicked,
} from '../session/session.actions';
import { Modals, RouteUrls } from '../../core/constants';
import { Contact as MembersApiContact, GetMyMemberResponse, MembersNgApi } from '@wix/ambassador-members-ng-api/http';
import { getSignedInstance } from '../session/session.probe';
import { componentSettings } from '../../components/MainPage/componentSettings';
import _ from 'lodash';
import { EmailValidator } from 'commons-validator-js';
import { LocaldeliveryOrderErrorCode } from '@wix/ambassador-restaurants-local-delivery/http';
import {
  submitOrderSuccess as submitOrderSuccessBi,
  submitOrderFailure,
  futureOrdersError,
  submitOrder,
} from '@wix/bi-logger-olo-client/v2';

export function handleOrderSuccess({
  orderResponse,
  dispatch,
  flowAPI,
  requestId = '',
  restaurant,
  menu,
  isDeliveryPartner,
}: {
  orderResponse: Order;
  dispatch: Dispatch<Action<any>>;
  flowAPI: ControllerFlowAPI;
  requestId?: string;
  restaurant: Restaurant;
  menu: Menu;
  isDeliveryPartner: boolean;
}) {
  dispatch(submitOrderSuccess({ orderResponse }));

  const tax = _(orderResponse.orderCharges)
    .filter((orderCharge) => menu.chargesV2!.find((c) => c.id === orderCharge.chargeId)!.type === 'tax')
    .map('amount')
    .sum();
  flowAPI.environment.isViewer &&
    orderResponse.orderItems &&
    flowAPI.controllerConfig.wixCodeApi.window.trackEvent('Purchase', {
      id: orderResponse.id,
      affiliation: restaurant.title[restaurant.locale],
      revenue: Number(orderResponse.price / 100),
      tax: Number(tax / 100),
      origin: 'Restaurants',
      currency: restaurant.currency,
      contents: orderResponse.orderItems.map((orderItem) => {
        const item = _.find(menu.items, (i) => i.id === orderItem.itemId);
        return {
          id: item && item.id,
          quantity: orderItem.count || 1,
          price: Number(orderItem.price / 100),
        };
      }),
    });

  dispatch(navigate({ routeUrl: RouteUrls.THANK_YOU }));
  dispatch(clearSessionStorage());
  dispatch(setIsLocationPicked({ value: false }));

  if (flowAPI.bi) {
    flowAPI.bi.report(
      submitOrderSuccessBi({
        orderId: orderResponse.id,
        oloRequestId: requestId,
        isDeliveryPartner,
        isPos: !!orderResponse.posInfo,
        posProviderId: orderResponse?.posInfo?.posProviderId,
      }),
    );
  }
}

export const getMemberContactDetails = async (flowAPI: ControllerFlowAPI): Promise<MembersApiContact | undefined> => {
  if (
    !flowAPI.environment.isViewer ||
    !flowAPI.controllerConfig.wixCodeApi.user.currentUser.loggedIn ||
    !flowAPI.settings.get(componentSettings.hasMembersAreaIntegration)
  ) {
    return;
  }

  const signedInstance = getSignedInstance(flowAPI);
  const headers = { Authorization: signedInstance };
  const membersApi = MembersNgApi('/_api/members').Members()(headers);
  try {
    const { member }: GetMyMemberResponse = await membersApi.getMyMember({ fieldSet: 'FULL' });
    return member?.contact;
  } catch (e) {
    // in case of a new sign up, sometimes members API still doesnt recognize the new member id. we need to retry
    try {
      return await new Promise((resolve) => {
        setTimeout(async () => {
          const { member }: GetMyMemberResponse = await membersApi.getMyMember({ fieldSet: 'FULL' });
          resolve(member?.contact);
        }, 2000);
      });
    } catch (error) {
      console.log('Failed to fetch new member info from members api');
    }
  }
};

export function handleOrderFailure({
  orderResponseType = '',
  orderResponseDetail = '',
  orderResponseCode,
  orderResponseChargeId,
  dispatch,
  flowAPI,
  requestId = '',
  orderResponseParams = { estimateFee: '0', estimateId: '' },
  tpaConfigurationId,
  isDeliveryPartner,
  posProviderId,
}: {
  orderResponseType?: string;
  orderResponseDetail?: string;
  orderResponseParams?: { estimateFee?: string; estimateId?: string };
  orderResponseCode?: number | string;
  orderResponseChargeId?: string;
  dispatch: Dispatch<Action<any>>;
  flowAPI: ControllerFlowAPI;
  requestId?: string;
  tpaConfigurationId?: string;
  isDeliveryPartner: boolean;
  posProviderId?: string;
}) {
  let errorType = '';
  let estimateTpaErrorCode;
  if (orderResponseType) {
    errorType = orderResponseType.substring(orderResponseType.lastIndexOf('/') + 1);
  }
  if (orderResponseCode === ErrorCodes.DELIVERY_ESTIMATION_REJECTED_BY_PROVIDER) {
    try {
      const estimateFailDetail = JSON.parse(orderResponseDetail);
      estimateTpaErrorCode = estimateFailDetail.errors[0].code;
    } catch (e) {
      estimateTpaErrorCode = LocaldeliveryOrderErrorCode.INVALID_WEIGHT_OR_VOLUME;
    }
  }

  if (orderResponseCode === ErrorCodes.FEE_DOESNT_MATCH && tpaConfigurationId) {
    try {
      const fee = Number.parseFloat(orderResponseParams.estimateFee || '0') * 100;
      const estimateId = orderResponseParams.estimateId || '0';
      dispatch(setDeliveryProviderEstimate({ estimateId, configurationId: tpaConfigurationId, fee }));
      dispatch(setCheckoutStep({ step: 'payments' }));
      dispatch(saveStateToSessionStorage());
    } catch (e) {
      console.error('unable to parse estimate fee ', orderResponseParams);
    }
  }
  dispatch(
    setErrorType({
      errorType: errorType as OrderFailureType,
      errorCode: orderResponseCode,
      errorChargeId: orderResponseChargeId,
      estimateTpaErrorCode,
    }),
  );

  dispatch(openModal({ modal: Modals.ORDER_FAILURE_MODAL }));

  if (flowAPI.bi) {
    flowAPI.bi.report(
      submitOrderFailure({
        rejectReason: orderResponseType,
        oloRequestId: requestId,
        errorCodeString: orderResponseCode?.toString(),
        isDeliveryPartner,
        isPos: !!posProviderId,
        posProviderId,
      }),
    );
    if (orderResponseDetail && orderResponseDetail.includes('earliest allowed delivery.time')) {
      flowAPI.bi.report(futureOrdersError({ details: orderResponseDetail.split('order')[0] }));
    }
  }
}

export function reportSubmitOrderBiEvent({
  flowAPI,
  restaurant,
  requestId,
  total,
  totalItemsCount,
  isContactless,
  isConsentRequired,
  isConsentCheckboxChecked,
  loyaltyPoints,
  dispatchType,
  contactlessDineInInputLabel,
  contactlessDineInUOUInput,
  discount_subtotal,
  tipType,
  tipSubtotal,
  tipButtonValue,
  order,
  contactId,
  configurationId,
  deliveryFee,
}: {
  flowAPI: ControllerFlowAPI;
  restaurant: Restaurant;
  requestId: string;
  total: number;
  totalItemsCount: number;
  isContactless: boolean;
  isConsentRequired: boolean;
  isConsentCheckboxChecked: boolean;
  loyaltyPoints?: number;
  dispatchType?: VirtualDispatchType;
  contactlessDineInInputLabel?: string;
  contactlessDineInUOUInput?: string;
  discount_subtotal?: number;
  tipType?: string;
  tipSubtotal?: number;
  tipButtonValue?: string;
  order?: Order;
  contactId?: string;
  configurationId?: string;
  deliveryFee?: number;
}) {
  if (flowAPI.bi) {
    flowAPI.bi.report(
      submitOrder({
        currency: restaurant.currency,
        oloRequestId: requestId,
        total: Math.round(total),
        totalItemsCount,
        isContactless,
        isConsentRequired,
        isConsentCheckboxChecked,
        dispatchType,
        contactlessDineInUOUInput,
        contactlessDineInInputLabel,
        discount_subtotal,
        tipSubtotal,
        tipType,
        tipValue1: tipButtonValue,
        loyaltyPoints,
        loyaltyPrice: order?.loyalty?.moneyAmount,
        totalLoyaltyPoints: order?.loyalty?.estimatedAccountBalance,
        loyaltyEstimatedEarend: order?.loyalty?.estimatedPointsEarned,
        contactId,
        isPos: !!restaurant.posProviderId,
        posProviderId: restaurant.posProviderId,
        configurationId,
        isDeliveryPartner: configurationId !== undefined,
        deliveryFee: deliveryFee ? Math.floor(deliveryFee / 100) : 0,
      }),
    );
  }
}

export async function fillInMissingContactDataInMembersArea(
  flowAPI: ControllerFlowAPI,
  oloContact: Contact,
  membersApiContact?: MembersApiContact,
) {
  if (!flowAPI.controllerConfig.wixCodeApi.user.currentUser.loggedIn) {
    return;
  }

  if (membersApiContact) {
    const { shouldBeUpdated, contact } = constructContactToBeUpdated(membersApiContact, oloContact);
    if (shouldBeUpdated) {
      const signedInstance = getSignedInstance(flowAPI);
      const headers = { Authorization: signedInstance };
      const membersApi = MembersNgApi('/_api/members').Members()(headers);

      await membersApi.updateMember({
        member: { id: flowAPI.controllerConfig.wixCodeApi.user.currentUser.id, contact },
      });
    }
  }
}

export function isContactValid({ email, phone }: { email: string; phone: string }) {
  const emailIsValid = new EmailValidator().isValid(email);
  return emailIsValid && phone.length !== 0;
}

function constructContactToBeUpdated(membersApiContact: MembersApiContact, oloContact: Contact) {
  const shouldBeUpdated = !(
    membersApiContact.phones?.find((phone) => phone === oloContact.phone) &&
    membersApiContact.firstName === oloContact.firstName &&
    membersApiContact.lastName === oloContact.lastName &&
    membersApiContact.emails?.find((email) => email === oloContact.email)
  );
  const contact: MembersApiContact = {};
  if (shouldBeUpdated) {
    contact.firstName = membersApiContact.firstName === oloContact.firstName ? undefined : oloContact.firstName;
    contact.lastName = membersApiContact.lastName === oloContact.lastName ? undefined : oloContact.lastName;
    if (!membersApiContact.phones) {
      contact.phones = [oloContact.phone];
    }

    if (
      membersApiContact.phones &&
      !(membersApiContact.phones[0] && membersApiContact.phones[0] === oloContact.phone)
    ) {
      contact.phones = [...membersApiContact.phones];
      contact.phones.shift();
      contact.phones.unshift(oloContact.phone);
    }
  }
  return {
    shouldBeUpdated,
    contact,
  };
}
